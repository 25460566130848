import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "switch",
      "style": {
        "position": "relative"
      }
    }}>{`Switch`}</h1>
    <p>{`É utilizado para trocar rapidamente entre dois possíveis estados. São muito usados para situações como "Ligado/Desligado" e ações que ocorrem imediatamente após o usuário mudar o `}<em parentName="p">{`switch`}</em>{`.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Utilize descrições junto ao componente para que a ação fique clara.`}</li>
      <li parentName="ul">{`Use adjetivos ao invés de verbos para descrever as labels e o estado do switch (Ex: Ligado, Desligado).`}</li>
      <li parentName="ul">{`A descrição deve permanecer no lado direito do switch.`}</li>
      <li parentName="ul">{`Sua função é habilitar ou desabilitar uma única função. Caso precise de um componente para fazer uma seleção entre mais opções, utilize `}<a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a>{` ou `}<a parentName="li" {...{
          "href": "/components/radio-button"
        }}>{`Radio Button`}</a>{`.`}</li>
      <li parentName="ul">{`No estado de `}<em parentName="li">{`hover`}</em>{`, utilize `}<em parentName="li">{`hint`}</em>{` com verbos indicando ao usuário o resultado do status caso complete a ação (Ex.: Desabilitar envio automático).`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <Demo src='pages/components/switch/SwitchDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      